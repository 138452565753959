/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const TermsOfService = () => (
  <Layout className="terms-of-service-page">
    <SEO 
      title="Terms of Service | HR Tools & Software" 
      description="Terms and conditions for using our HR tools and services. Read our usage guidelines, limitations, and legal requirements."
      keywords={[
        "terms of service",
        "terms and conditions",
        "usage agreement",
        "legal terms",
        "service terms",
        "HR software terms",
        "compliance requirements",
        "data usage terms"
      ]}
    />
    <div className="wrapper" sx={{ 
      maxWidth: '1000px', 
      margin: '0 auto', 
      padding: '40px 20px'
    }}>
      {/* Quick Navigation */}
      <div sx={{
        bg: 'white',
        p: 4,
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        mb: 4
      }}>
        <h2 sx={{ fontSize: '1.2rem', mb: 3, color: 'primary' }}>Quick Navigation</h2>
        <div sx={{ 
          display: 'grid', 
          gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
          gap: 3
        }}>
          <a href="#agreement" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>Agreement</a>
          <a href="#usage" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>Usage Terms</a>
          <a href="#hr-compliance" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>HR Compliance</a>
          <a href="#liability" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>Liability</a>
          <a href="#modifications" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>Changes</a>
          <a href="#contact" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>Contact</a>
        </div>
      </div>

      <h1 sx={{ 
        fontSize: ['1.75rem', '2rem', '2.25rem'], 
        mb: 4,
        color: 'primary',
        textAlign: 'center'
      }}>
        Terms of Service
      </h1>

      <div sx={{ 
        bg: 'white', 
        p: 4, 
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        mb: 4
      }}>
        <div sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
          pb: 3,
          borderBottom: '1px solid',
          borderColor: 'gray.2'
        }}>
          <p sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
            Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
          </p>
          <Link to="/privacy" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>
            View Privacy Policy
          </Link>
        </div>

        <section id="agreement" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Agreement to Terms</h2>
          <div sx={{
            p: 3,
            bg: 'gray.1',
            borderRadius: '4px',
            mb: 3
          }}>
            <p sx={{ mb: 3, lineHeight: 1.6 }}>
              By accessing or using our HR tools and services, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not access our services.
            </p>
            <p sx={{ lineHeight: 1.6 }}>
              These terms are designed to ensure proper use of our HR technology solutions while maintaining compliance with relevant employment and data protection laws.
            </p>
          </div>
        </section>

        <section id="usage" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Use of Services</h2>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr'],
            gap: 4,
            mb: 4
          }}>
            <div sx={{
              p: 3,
              border: '1px solid',
              borderColor: 'gray.2',
              borderRadius: '4px'
            }}>
              <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Acceptable Use</h3>
              <ul sx={{ pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
                <li>Use tools for intended HR purposes</li>
                <li>Provide accurate information</li>
                <li>Maintain data confidentiality</li>
                <li>Follow compliance guidelines</li>
                <li>Respect intellectual property</li>
              </ul>
            </div>

            <div sx={{
              p: 3,
              border: '1px solid',
              borderColor: 'gray.2',
              borderRadius: '4px'
            }}>
              <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Prohibited Activities</h3>
              <ul sx={{ pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
                <li>Unauthorized system access</li>
                <li>Data misuse or manipulation</li>
                <li>Service interference</li>
                <li>Policy violations</li>
                <li>Unauthorized commercial use</li>
              </ul>
            </div>
          </div>

          <div sx={{
            bg: 'primary',
            color: 'white',
            p: 3,
            borderRadius: '4px',
            mb: 4
          }}>
            <strong>Important:</strong> Our tools are designed for HR professionals and authorized personnel only. Unauthorized access or misuse may result in immediate termination of service.
          </div>
        </section>

        <section id="hr-compliance" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>HR Compliance Requirements</h2>
          
          <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Data Handling</h3>
          <ul sx={{ mb: 4, pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
            <li>Compliance with employment data laws</li>
            <li>Proper handling of sensitive information</li>
            <li>Secure storage of employee data</li>
            <li>Confidentiality requirements</li>
            <li>Data retention policies</li>
          </ul>

          <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Legal Requirements</h3>
          <ul sx={{ mb: 4, pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
            <li>Employment law compliance</li>
            <li>Privacy law adherence</li>
            <li>Industry regulations</li>
            <li>Documentation requirements</li>
          </ul>
        </section>

        <section id="liability" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Liability & Disclaimers</h2>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
            gap: 4,
            mb: 4
          }}>
            <div sx={{
              textAlign: 'center',
              p: 3,
              bg: 'gray.1',
              borderRadius: '4px'
            }}>
              <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>Service Provision</h4>
              <p>"As is" and "as available" basis</p>
            </div>
            <div sx={{
              textAlign: 'center',
              p: 3,
              bg: 'gray.1',
              borderRadius: '4px'
            }}>
              <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>Professional Advice</h4>
              <p>Not a substitute for legal counsel</p>
            </div>
            <div sx={{
              textAlign: 'center',
              p: 3,
              bg: 'gray.1',
              borderRadius: '4px'
            }}>
              <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>User Responsibility</h4>
              <p>Verify compliance with local laws</p>
            </div>
          </div>

          <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Indemnification</h3>
          <ul sx={{ mb: 4, pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
            <li>User indemnification obligations</li>
            <li>Defense cooperation requirements</li>
            <li>Claim notification procedures</li>
            <li>Cost and expense coverage</li>
          </ul>
        </section>

        <section id="modifications" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Modifications & Updates</h2>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr'],
            gap: 4
          }}>
            <div>
              <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Service Changes</h3>
              <ul sx={{ pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
                <li>Right to modify features</li>
                <li>Service improvement updates</li>
                <li>Compliance-related changes</li>
                <li>Notice of major updates</li>
              </ul>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Terms Updates</h3>
              <ul sx={{ pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
                <li>Regular policy reviews</li>
                <li>Regulatory update integration</li>
                <li>User notification process</li>
                <li>Continued use acceptance</li>
              </ul>
            </div>
          </div>
        </section>

        <section id="contact" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Contact Information</h2>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr'],
            gap: 4
          }}>
            <div>
              <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Legal Department</h3>
              <ul sx={{ pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
                <li>Email: legal@example.com</li>
                <li>Phone: (555) 123-4567</li>
                <li>Address: 123 Legal Street, Suite 100, San Francisco, CA 94105</li>
              </ul>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Support Hours</h3>
              <ul sx={{ pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
                <li>Monday - Friday: 9am - 6pm PT</li>
                <li>Emergency Support: 24/7</li>
                <li>Response Time: 1-2 business days</li>
              </ul>
            </div>
          </div>
        </section>

        <div sx={{
          mt: 5,
          pt: 4,
          borderTop: '1px solid',
          borderColor: 'gray.2',
          textAlign: 'center'
        }}>
          <p sx={{ fontSize: '0.9rem', color: 'gray.7' }}>
            For more information about how we handle your data, please review our{' '}
            <Link to="/privacy" sx={{ color: 'primary' }}>Privacy Policy</Link> or{' '}
            <a href="#agreement" sx={{ color: 'primary' }}>return to top</a>.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default TermsOfService 